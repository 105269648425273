
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import { default as BgConstructor } from '../../Settings/BgConstructor/BgConstructor.vue';

import { ImgPreview, Loader } from '../../../components/ui';

import { computed, defineComponent, ref } from 'vue-demi';
import { Stores, useStores } from '@/store/Stores';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import SceneConstructor from '../../Settings/SceneConstructor/SceneConstructor.vue';
import { useRoute } from 'vue-router';
import { number } from '@intlify/core-base';

export default defineComponent({
  props: {
    adminMode: Boolean,
    companyId: Number
  },
  data() {
    return {
      isConstructorShown: false,
      isSceneConstructorShown: false,

      companyId: +this.$route.params.companyID
    };
  },
  methods: {
    showConstructor() {
      this.isConstructorShown = true;
    },

    showSceneConstructor() {
      this.isSceneConstructorShown = true;
    }
  },
  mounted() {
    this.adminCarStore.initRooms(this.companyId);
  },
  setup() {
    const { adminCarStoreState, adminCarStore } = useStores();

    const route = useRoute();
    const { t } = useI18n();
    const toast = useToast();

    return {
      toast,
      t,

      adminCarStore,
      adminCarStoreState
    };
  },
  components: { Toolbar, FileUpload, ImgPreview, Loader, Dialog, BgConstructor, SceneConstructor }
});
